*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: medium;
  min-height: 100vh;
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #faf6f6;
  color: #2a2c2c;
}

.Contact {
  margin-top: 2rem;
  display: flex;
  gap: 1em;
  justify-content: center;
}

.Name {
  margin-top: 0;
  margin-bottom: 2rem;
}

.Title {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.Link {
  text-decoration: none;
  color: #135dbe;

  &:hover {
    box-shadow: 0 2px 0 0 currentColor;
  }
}
